:root {
  --color-primary: #f18f01;
  --color-secondary: #212121;
  --color-tertiary: #474647;
  --color-quaternary: #eff7ff;
  --color-quinary: #ffffff;
}

.App {
  text-align: center;
  background-color: var(--color-secondary);
}

.App-brand {
  height: 17vmin;
  pointer-events: none;
  padding-bottom: 2rem;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: 100;
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-motiv {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-card-container {
    align-items: center;
    display: flex;
    min-height: 100vh;
    justify-content: center;
}

.App-card-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px 24px;
    max-width: 100%;
    width: 90%;
}

@media (min-width: 540px) {
    .App-card-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 960px) {
    .App-card-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

.App-about {
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
}

.App-about-left {
  width: 65%;
  font-size: calc(10px + 2vmin);
  font-weight: 100;
  text-align: justify;
}

footer {
  position: fixed;
  padding: 10px 0px 10px 0px;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 40px;
}

.logo {
  /* display: flex; */
  height: 100%;
}

.highlight {
  color: var(--color-primary);
}

