/* --background-dark: #2d3548 */

.card {
    list-style: none;
    position: relative;
}

.card::before {
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
}

.card__background {
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    bottom: 0;
    filter: brightness(0.75) saturate(1.2) contrast(0.85);
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition: filter 200ms linear,
        transform 200ms linear;
}

.card:hover .card__background {
    transform: scale(1.05) translateZ(0);
}

.card-grid:hover > .card:not(:hover) .card__background {
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
}

.card__content {
    left: 0;
    top: 0;
    padding: 24px;
    position: absolute;
}

.card__category {
    color: rgba(255,255,255,0.6);
    font-size: 0.9rem;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.card__heading {
    color: rgba(255,255,255,0.9);
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0,0,0,0.6);
    line-height: 1.4;
}